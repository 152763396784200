.distribution{
	.inside-content{
		b{
			font-family: pt_sans_bold;
			color: #324141;
			font-size: 20px;
			font-weight: normal;
			padding: 30px 0 20px;
			display: block;
		}
		.desc_text{
			width: 765px;
			ol{
				width: 640px;
				margin-top: 10px;
				margin-left: 40px;
				li{
					padding-top: 25px;
					padding-left: 10px;
					color: #bf2267;
					span{color: #324141;}
				}
			}
		}//desc_text

		.cooperation{
			ol{
				margin-top: 35px;
				li{
					margin-bottom: 30px;
					color: #bf2267;
					font-size: 20px;
					font-family: pt_sans_bold;
					float: left;
					width: 440px;
					min-height: 200px;
					&:nth-child(2n){
						margin-left: 80px;
					}
					.text{
						padding-top: 20px;
						color: #324141;
						font-family: pt_sans_regular;
						font-size: 16px;
						line-height: 1.5;
					}
				}
			}
		}//cooperation

		.partners{
			clear: both;
			b{font-size: 24px;}
			ul{
				margin-top: 50px;
				list-style: none;
				li{
					display: inline-block;
					vertical-align: middle;
					width: 235px;
					text-align: center;
					margin: 25px 0;
				}
			}
		}//partners

		.map{
			text-align: center;
		}

	}//inside-content
}//distribution
