.trademark {
    .inside-content {
        .trademark-items {
            margin-top: 50px;

            ul {
                list-style: none;

                li {
                    display: inline-block;
                    vertical-align: bottom;

                    &:nth-child(2) { margin: 0px 6% 0 10%; }

                    a {
                        color: #c1c6c6;

                        img {
                            display: block;
                            max-width: 350px;
                            border: none;
							margin: 0 auto;
                        }

                        span {
                            display: block;
                            text-align: center;
                            color: #324141;
                            font-family: pt_sans_bold;
                            font-size: 20px;
                            line-height: 3;
                        }

                        &:hover, &:hover > span { color: #bf2267; }
                    }
                }
            }
        }

        //trademark-items
        .text-bottom {
            margin-top: 37px;
            margin-bottom: 65px;

            ul {
                list-style: none;

                li {
                    display: inline-block;
                    width: 421px;
                    vertical-align: top;
                    font-size: 14px;

                    &:first-child { margin-right: 113px; }
                }
            }
        }
        //text-bottom
    }
    //inside-content
}
//trademark
