.page-404 {
    text-align: center;

    h1 {
        font-size: 80px;
        margin: 80px 0;
        line-height: normal;
    }

    a {
        color: #55a0bc;

        &:hover { color: #BF2267; }
    }
}
