// main: ../main.less

.vacancies{
	.inside-content{
		.vacancies-view{
			list-style: none;
			margin-bottom: 60px;
			li{
				margin-top: 20px;
				&:first-child{margin: 0;}
				a{
					font-family: pt_sans_bold;
					font-size: 24px;
					text-decoration: none;
					border-bottom: 1px dashed #c1c6c6;
					display: inline-block;
					line-height: 25px;
					&:hover{border-bottom: 1px dashed #BF2267;}
				}
				.text{
					display: none;
					p{

						font-family: pt_sans_bold;
						font-size: 18px;
						color: #bf2267;
						margin-top: 25px;
					}
					ul{

						margin-top: 12px;
						margin-left: 32px;
						list-style: disc;
						li{
							margin: 0;
						}
					}
				}
			}
		}//vacancies-view
	}//inside-content
}//vacancies
