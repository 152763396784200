.structure{
	#holding{
		list-style-type: none;
		line-height: normal;
		li{
			width: 362px;
			display: inline-block;
			vertical-align: top;
			padding: 0 57px;
			color: #324141;
			font-size: 14px;
			font-family: pt_sans_regular;
			line-height: 23px;
			margin-bottom: 75px;
			.img{
				line-height: 214px;
				img{
					vertical-align: middle;
				}
			}
			h3{
				font-size: 16px;
				font-family: pt_sans_bold;
				font-weight: normal;
				line-height: 1.5;
			}
			ul{
				margin-top: 7px;
				list-style: disc;
				li{
					padding: 0;
					display: list-item;
					margin-left: 13px;
					margin-bottom: 0;
				}
			}
		}
	}//holding
	#prewiev-text{
		margin-bottom: 35px;
		width: 640px;
	}
	.advantages{
		border-bottom: 1px solid #e0e2e2;
		border-top: 1px solid #e0e2e2;
		margin-bottom: 25px;
		padding: 74px 0;
		ul{
			list-style-type: none;
			li{
				vertical-align: top;
				display: inline-block;
				width: 239px;
				text-align: center;
				margin: 0 39px;
				color: #bf2267;
				font-size: 20px;
				font-family: pt_sans_bold;
				line-height: normal;
			}
		}
	}//advantages
}//structure
