@import "footer-block.less";
@import "../main.less";

.detailNewsMixin() {
    h1 {
        color: #55a0bc !important;
        font-size: 32px !important;
        margin-bottom: 32px;
    }

    .prewiev-text {
        margin-top: 25px;
        font-family: pt_sans_bold;
        font-size: 20px;
        margin-bottom: 35px;
    }

    .text {
        img {
            margin-top: 65px;
            margin-bottom: 25px;
        }

        span {
            display: inline-block;
            vertical-align: top;
            width: 450px;
            padding-left: 95px;
            padding-top: 65px;
        }
    }
}

.news {
    .inside-content {
        .lastNewsMixin();

        .other_news {
            border-top: 1px solid #e0e2e2;
            padding-top: 48px;
            margin-top: 43px;

            ul {
                list-style: none;

                li {
                    width: 455px;
                    display: inline-block;
                    font-size: 13px;
                    margin-bottom: 40px;
                    margin-right: 45px;
					vertical-align: top;

                    &:nth-child(2n) { margin-right: 0; }

                    .img {
                        display: table-cell;
                        padding-right: 25px;

                        img { border: none; }
                    }

                    .prewiev-text {
                        display: table-cell;
                        vertical-align: top;

                        a {
                            color: #c1c6c6;
                            font-family: pt_sans_bold;
                            font-size: 18px;
                            line-height: 1;
                            margin-bottom: 12px;
                            display: block;

                            &:hover {
                                color: #BF2267;

                                span { color: #BF2267; }
                            }

                            span { color: #324141; }
                        }
                    }
                }
            }
        }
        //other_news
    }

    //inside-content
    .news-detail {
        width: 735px;
        .detailNewsMixin();
    }
	.photogalery{
			margin-top: 40px;
			ul{
				li{
					display: inline-block;
					margin-right: 48px;
					margin-bottom: 40px;

					&:nth-child(4n){
						margin-right: 0;
					}
				}
			}
		}

    //news-detail
    .next-news {
        border-top: 1px solid #E0E2E2;
        margin-top: 70px;
        padding-top: 73px;
        margin-bottom: 72px;

        ul {
            list-style: none;
            width: 590px;
            margin: 0 auto;

            li {
                display: inline-block;
                width: 230px;
                vertical-align: top;
                position: relative;
                padding-right: 20px;

                &:first-child {
                    margin-right: 65px;
                    padding-left: 20px;

                    span { left: -10px; }
                }

                a {
                    font-family: pt_sans_bold;
                    color: #C2C6C6;

                    &:hover {
                        color: #BF2267;

                        p { color: #BF2267; }
                    }

                    p {
                        display: block;
                        color: #314040;
                    }

                    img { height: 121px; }
                }

                span {
                    position: absolute;
                    bottom: 10%;
                    right: 0px;
                }
            }
        }
    }
}
//news
