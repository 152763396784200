//import
@import 'pages/structure.less';
@import 'pages/distributor.less';
@import 'pages/footer-block.less';
@import 'pages/form.less';
@import 'pages/manufacture.less';
@import 'pages/choice-tm.less';
@import 'pages/item.less';
@import 'pages/feedback-catalog.less';
@import 'pages/contacts.less';
@import 'pages/geography-of-deliveries.less';
@import 'pages/honors.less';
@import 'pages/fancybox.less';
@import 'pages/news.less';
@import 'pages/pagination.less';
@import 'pages/publishing.less';
@import 'pages/reviews.less';
@import 'pages/vacancies.less';
@import 'pages/distribution.less';
@import 'pages/presentation.less';
@import 'pages/404.less';
@import 'pages/konkurs.less';

//paths
@tplPath: '/bitrix/templates/main/';
@imgPrefix: '@{tplPath}images/';
@fontsPrefix: '@{tplPath}fonts/';

//fonts
@font-face {
    font-family: 'pt_sans_bold';
    src: url('@{fontsPrefix}pt_sans_bold-webfont.eot');
    src: url('@{fontsPrefix}pt_sans_bold-webfont.eot?#iefix') format('embedded-opentype'), url('@{fontsPrefix}pt_sans_bold-webfont.woff2') format('woff2'), url('@{fontsPrefix}pt_sans_bold-webfont.woff') format('woff'), url('@{fontsPrefix}pt_sans_bold-webfont.ttf') format('truetype'), url('@{fontsPrefix}pt_sans_bold-webfont.svg#pt_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arimo';
    src: url('@{fontsPrefix}arimo-bold-webfont.eot');
    src: url('@{fontsPrefix}arimo-bold-webfont.eot?#iefix') format('embedded-opentype'), url('@{fontsPrefix}arimo-bold-webfont.woff2') format('woff2'), url('@{fontsPrefix}arimo-bold-webfont.woff') format('woff'), url('@{fontsPrefix}arimo-bold-webfont.ttf') format('truetype'), url('@{fontsPrefix}arimo-bold-webfont.svg#arimobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pt_sans_regular';
    src: url('@{fontsPrefix}pt_sans_regular-webfont.eot');
    src: url('@{fontsPrefix}pt_sans_regular-webfont.eot?#iefix') format('embedded-opentype'), url('@{fontsPrefix}pt_sans_regular-webfont.woff2') format('woff2'), url('@{fontsPrefix}pt_sans_regular-webfont.woff') format('woff'), url('@{fontsPrefix}pt_sans_regular-webfont.ttf') format('truetype'), url('@{fontsPrefix}pt_sans_regular-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }

a {
    color: #324141;
    outline: none;

    &:hover { color: #BF2267; }
}

html {
    height: 100%;

    body {
        font: 12px/18px Arial, sans-serif;
        height: 100%;

        .wrapper {
            width: 100%;
            min-width: 980px;
            min-height: 100%;
            height: auto !important;
            height: 100%;

            .header {
                height: 128px;
                background: url("@{imgPrefix}header.jpg") repeat-x;
                position: fixed;
                top: 0px;
                width: 100%;
                z-index: 2;
                min-width: 980px;

                #logo {
                    background: url("@{imgPrefix}logo.png") no-repeat center center;
                    display: block;
                    width: 150px;
                    height: 78px;
                    float: left;
                    margin-top: 20px;
                }

                #right-column {
                    float: right;

                    #contact-head {
                        margin-top: 35px;
                        text-align: right;

                        b {
                            font-size: 16px;
                            line-height: 28px;
                            font-family: arimo;
                            color: #303030;
                            font-weight: normal;
                        }

                        #call-me {
                            display: inline-block;
                            margin-left: 20px;
                            background: url("@{imgPrefix}telephone.png") no-repeat 10px center #55a0bc;
                            color: #fff;
                            text-decoration: none;
                            font-size: 13px;
                            padding: 4px 0;
                            padding-right: 16px;
                            padding-left: 35px;
                            -webkit-border-radius: 12px;
                            -moz-border-radius: 12px;
                            border-radius: 12px;
                            font-family: pt_sans_regular;

                            &:hover { background: url("@{imgPrefix}telephone.png") no-repeat 10px center #bf2267; }
                        }
                    }

                    //contact-head
                    #top-menu {
                        margin-top: 25px;

                        li {
                            display: inline-block;
                            margin-left: 20px;
                            padding-bottom: 5px;

                            &:first-child { margin: 0; }

                            a {
                                color: #303030;
                                font-size: 14px;
                                text-decoration: none;
                                text-transform: uppercase;
                                font-family: pt_sans_regular;

                                &:hover { color: #BF2267; }
                            }

                            .active { color: #BF2267; }

                            .inside-menu {
                                position: absolute;
                                display: none;
                                margin-top: 5px;

                                li {
                                    display: block;
                                    margin: 0;
                                    padding: 0;
                                    background: #55a0bc;

                                    a {
                                        width: 175px;
                                        padding: 7px 6px;
                                        display: block;
                                        color: #FFF;

                                        &:hover {
                                            background: #bf2267;
                                            color: #FFF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    //top-menu
                }
                //right-column
            }

            //header
            .content {
                padding: 128px 0 100px;

				#main-banner{
					background: #bf2267;
					display: block;
					width: 100%;
					height: 173px;

					img{
						display: block;
						margin: 0 auto;
					}
				}

                #main-top {
                    background: url("@{imgPrefix}main-top.png") repeat-x;
                    height: 358px;

                    .content-frame {
                        color: #FFF;
                        font-size: 18px;
                        line-height: 22px;
                        max-width: 100%;
                        min-width: 635px;
                        width: 637px;
                        padding-top: 90px;
                        font-family: pt_sans_bold;

                        img {
                            float: left;
                            padding-right: 27px;
                        }
                    }
                }

                //main-top
                #main-bottom {
                    background: url("@{imgPrefix}main-bottom.jpg");
                    height: 630px;
                    margin-bottom: 174px;

                    .content-frame {
                        position: relative;
                        top: -125px;

                        .main-items {
                            display: inline-block;
                            position: relative;

                            &:nth-child(2) { margin: 0px 4% 0px -3%; }

                            img { border: none; }

                            &:first-child .circle-description { margin-left: -5%; }

                            .circle-description {
                                display: none;
                                width: 270px;
                                height: 270px;
                                border-radius: 100%;
                                -moz-border-radius: 100%;
                                -webkit-border-radius: 100%;
                                position: absolute;
                                top: 35%;

                                .circle-content {
                                    display: table;
                                    margin: 85px auto 0px;

                                    h1 {
                                        font-family: pt_sans_bold;
                                        color: #FFF;
                                        font-size: 18px;
                                        text-transform: uppercase;
                                        line-height: 27px;
                                        font-weight: normal;
                                    }

                                    p {
                                        font-family: pt_sans_bold;
                                        font-size: 15px;
                                        color: #FFF;
                                    }

                                    ul {
                                        margin-top: 20px;
                                        list-style-type: none;
                                        color: #FFF;

                                        li {
                                            font-family: pt_sans_regular;
                                            font-size: 15px;
                                        }
                                    }
                                }
                                //circle-content
                            }

                            //circle-description
                            #mineral-water {
                                background-image: -ms-linear-gradient(top, #46C7F2 0%, #288DD6 100%);
                                background-image: -moz-linear-gradient(top, #46C7F2 0%, #288DD6 100%);
                                background-image: -o-linear-gradient(top, #46C7F2 0%, #288DD6 100%);
                                background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #46C7F2), color-stop(1, #288DD6));
                                background-image: -webkit-linear-gradient(top, #46C7F2 0%, #288DD6 100%);
                                background-image: linear-gradient(to bottom, #46C7F2 0%, #288DD6 100%);
                                left: 65px;

                                h1, ul { color: #fffadb; }

                                p { color: #0d568b; }
                            }

                            #limonad {
                                background-image: -ms-linear-gradient(top, #FFF6B0 0%, #FFC47D 100%);
                                background-image: -moz-linear-gradient(top, #FFF6B0 0%, #FFC47D 100%);
                                background-image: -o-linear-gradient(top, #FFF6B0 0%, #FFC47D 100%);
                                background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFF6B0), color-stop(1, #FFC47D));
                                background-image: -webkit-linear-gradient(top, #FFF6B0 0%, #FFC47D 100%);
                                background-image: linear-gradient(to bottom, #FFF6B0 0%, #FFC47D 100%);

                                h1, ul { color: #4c361c; }

                                p { color: #ff7336; }
                            }

                            #juice {
                                background-image: -ms-linear-gradient(top, #E64A2B 0%, #C02020 100%);
                                background-image: -moz-linear-gradient(top, #E64A2B 0%, #C02020 100%);
                                background-image: -o-linear-gradient(top, #E64A2B 0%, #C02020 100%);
                                background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #E64A2B), color-stop(1, #C02020));
                                background-image: linear-gradient(to bottom, #E64A2B 0%, #C02020 100%);
                                left: -15px;

                                p { color: #fff4ae; }

                                .circle-content { padding: 0 55px; }
                            }
                        }
                        //main-items
                    }
                    //content-frame
                }

                //main-bottom
                #presentation {
                    width: 951px;
                    height: 300px;
                    margin: 50px auto;
                    background-image: -ms-linear-gradient(top, #FFF8B1 0%, #FFC47D 100%);
                    background-image: -moz-linear-gradient(top, #FFF8B1 0%, #FFC47D 100%);
                    background-image: -o-linear-gradient(top, #FFF8B1 0%, #FFC47D 100%);
                    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFF8B1), color-stop(1, #FFC47D));
                    background-image: -webkit-linear-gradient(top, #FFF8B1 0%, #FFC47D 100%);
                    background-image: linear-gradient(to bottom, #FFF8B1 0%, #FFC47D 100%);
                    -webkit-border-radius: 15px;
                    -moz-border-radius: 15px;
                    border-radius: 15px;
                    -webkit-box-shadow: 0px 45px 40px -45px rgba(181,189,195,1);
                    -moz-box-shadow: 0px 45px 40px -45px rgba(181,189,195,1);
                    box-shadow: 0px 45px 40px -45px rgba(181,189,195,1);
                    padding: 25px 0;

                    a {
                        display: table;
                        margin: 0 auto;
                        text-decoration: none;

                        img {
                            border: none;
                            float: left;
                            padding: 0 50px;
                        }

                        p {
                            vertical-align: middle;
                            color: #303030;
                            font-size: 26px;
                            font-family: pt_sans_bold;
                            padding-left: 32px;
                            line-height: 30px;
                            display: table-cell;

                            span {
                                border-bottom: 1px solid #c0a977;

                                &:hover {
                                    color: #BF2267;
                                    border-bottom: 1px solid #BF2267;
                                }
                            }
                        }
                    }
                }

                //presentation
                .inside-content {
                    width: 960px;
                    margin: 0 auto;
                    padding: 0 16px;
                    color: #324141;
                    font-size: 16px;
                    font-family: pt_sans_regular;
                    line-height: 1.5;

                    h1 {
                        color: #bf2267;
                        font-family: pt_sans_bold;
                        font-weight: normal;
                        font-size: 80px;
                        line-height: 1;
                    }

                    #main-menu {
                        margin-bottom: 45px;
                        margin-top: 21px;
                        line-height: 1;

                        ul {
                            list-style-type: none;
                            display: inline-block;
                            -webkit-border-radius: 10px;
                            -moz-border-radius: 10px;
                            border-radius: 10px;
                            background: #ffdc08;

                            li {
                                display: inline-block;

                                &:first-child a {
                                    -webkit-border-top-left-radius: 10px;
                                    -webkit-border-bottom-left-radius: 10px;
                                    -moz-border-radius-topleft: 10px;
                                    -moz-border-radius-bottomleft: 10px;
                                    border-top-left-radius: 10px;
                                    border-bottom-left-radius: 10px;
                                }

                                &:last-child a {
                                    -webkit-border-top-right-radius: 10px;
                                    -webkit-border-bottom-right-radius: 10px;
                                    -moz-border-radius-topright: 10px;
                                    -moz-border-radius-bottomright: 10px;
                                    border-top-right-radius: 10px;
                                    border-bottom-right-radius: 10px;
                                }

                                a {
                                    color: #000000;
                                    font-family: pt_sans_regular;
                                    font-size: 16px;
                                    text-decoration: none;
                                    padding: 12px 15px;
                                    display: block;

                                    &:hover { color: #BF2267; }
                                }

                                .active {
                                    color: #FFF;
                                    background-image: -ms-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: -moz-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: -o-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #D65F44), color-stop(1, #C02565));
                                    background-image: -webkit-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: linear-gradient(to bottom, #D65F44 0%, #C02565 100%);

                                    &:hover { color: #FFF; }
                                }
                            }
                        }

                        ul.cities-list {
                            background-image: -ms-linear-gradient(top, #D65F44 0%, #C02565 100%);
                            background-image: -moz-linear-gradient(top, #D65F44 0%, #C02565 100%);
                            background-image: -o-linear-gradient(top, #D65F44 0%, #C02565 100%);
                            background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #D65F44), color-stop(1, #C02565));
                            background-image: -webkit-linear-gradient(top, #D65F44 0%, #C02565 100%);
                            background-image: linear-gradient(to bottom, #D65F44 0%, #C02565 100%);
                            li{
                                margin-top: 0;
                                a{
                                    color: #fff;
                                    background-image: -ms-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: -moz-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: -o-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #D65F44), color-stop(1, #C02565));
                                    background-image: -webkit-linear-gradient(top, #D65F44 0%, #C02565 100%);
                                    background-image: linear-gradient(to bottom, #D65F44 0%, #C02565 100%);
                                    font-size: 24px;
                                    font-family: pt_sans_bold;
                                    font-weight: normal;
                                    &:hover{
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }

                    //main-menu
                    h2 {
                        color: #55a0bc;
                        font-size: 32px;
                        font-family: pt_sans_bold;
                        font-weight: normal;
                    }

                }

				.video {
					position: relative;
					width: 954px;
					height: 537px;

					video {
						width: 954px;
						height: 537px;
					}

					.play {
						display: block;
						background: url('@{imgPrefix}control.png') no-repeat;
						width: 168px;
						height: 168px;
						position: absolute;
						left: 0;
						top: 0;
						z-index: 1;
						bottom: 0;
						right: 0;
						margin: auto;
					}
				}

                //inside-content
                .bottom-block {
                    background: url('@{imgPrefix}footer-block.jpg') repeat-x 0 top;
                    padding: 70px 0;
                }
            }
            //content
        }

        //wrapper
        .footer {
            margin: -25px auto 0;
            min-width: 980px;
            height: 25px;
            position: relative;

            #social-block {
                display: table;
                margin: 0 auto;

                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    display: inline-block;

                    &:nth-child(2) { margin: 0 29px; }

                    &:hover { background-position: 0 -43px !important; }
                }

                #facebook { background: url("@{imgPrefix}facebook.png") no-repeat center top; }

                #vk { background: url("@{imgPrefix}vk.png") no-repeat center top; }

                #instagram { background: url("@{imgPrefix}instagram.png") no-repeat center top; }
            }

            #developer {
                font-family: pt_sans_regular;
                color: #55a0bc;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 13px;
                background: url('@{imgPrefix}wi.png') no-repeat right top;
                padding-right: 23px;

                &:hover { background-position: right -17px !important; }
            }
        }

        //footer
        .content-frame {
            max-width: 1120px;
            min-width: 960px;
            margin: 0 auto;
            padding: 0 16px;
        }
    }
    //body
}
//html
