// main: ../main.less

.manufacture{
	
	.inside-content{
		
		b, p{max-width: 675px; display: block;}

		b{
			font-family: pt_sans_bold;
			font-weight: normal;
			font-size: 18px;
			margin-bottom: 16px;
		}
		
		p{margin: 18px 0 49px;}
		
		.img{
			width: 805px;
			height: 390px;
			border: 1px solid #d0d0d0;
			
		}
		
	}//inside-content
	
}//manufacture