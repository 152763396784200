.contacts {
    .inside-content ul {
        list-style: none;

        li {
            &:first-child { margin-top: 40px; }

            margin-top: 60px;

            h3 {
                margin-bottom: 25px;
                font-family: pt_sans_bold;
                font-size: 24px;
                font-weight: normal;
            }

            .map {
                position: relative;

                .info-map {
                    position: absolute;
                    top: 0px;
                    background: rgba(254, 253, 252, 0.8);
                    width: 240px;
                    height: 450px;

                    .text {
                        width: 200px;
                        margin: 0 auto;
                        color: #000;

                        p {
                            margin-top: 32px;

                            &:first-child { margin-top: 0; }
                        }

                        i {
                            margin-top: 15px;
                            font-size: 14px;
                            display: block;
                        }

                        a {
                            &:first-of-type { margin-top: 32px; }

                            display: block;
                            color: #55a0bc;
                        }
                    }
                }
            }
        }
    }
    //inside-content

}

//contacts
.castom_contacts {
    margin-top: 50px;

    ul li {
        display: inline-block;
    vertical-align: top;
        width: 250px;
        margin-right: 50px;
        margin-bottom: 50px;
    }
}

//castom_contacts
.rekvesits{
  margin: 30px 0;

  h2,h3{
    margin: 0 0 15px;
  }

  ul
  {
    margin: 15px 0 0;

    li{
      margin: 0 !important;
    }
  }
}
//rekvesits
