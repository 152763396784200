.reviews {
    .inside-content {
        .reviews-view {
            list-style: none;

            li {
                display: inline-block;
                width: 197px;
                vertical-align: top;
                margin-left: 53px;

                &:nth-child(4n + 1) { margin-left: 0; }

                margin-bottom: 45px;

                a {
                    font-family: pt_sans_bold;
                    font-size: 18px;
                    text-decoration: none;
                    color: #324141;
                    line-height: normal;

                    img {
                        display: block;
                        border: none;
                        margin-bottom: 15px;
                        width: 190px;
                        height: 270px;
                    }
                }
            }
        }
        //reviews-view
    }
    //inside-content
}
//reviews
