.formMixin(){
	h3{
		font-family: pt_sans_bold;
		font-weight: normal;
		color: #FFF;
		font-size: 30px;
		line-height: normal;
		padding-bottom: 21px;
	}
	.ts-alert{
		margin-bottom: 10px;
		line-height: 25px;
		font-family: pt_sans_bold;
		font-size: 20px;
	}
	.ts-alert-danger{
		color: #BF2267;

	}
	.ts-alert-success{
		color: #FFF;
		margin-bottom: 10px;
	}
	.close{
		text-decoration: none;
		color: #FFF;
		font-size: 42px;
		position: absolute;
		top: 20px;
		right: 20px;
		&:hover{color: #BF2267}
	}
	input{
		display: block;
		color: #324141;
		font-size: 16px;
		padding: 10px 15px;
		border: none;
		margin-bottom: 10px;

		&[type=number]::-webkit-inner-spin-button,
		&[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
    		margin:0;
		}
		&[type=number] {
    		-moz-appearance:textfield;
		}
		&[type=text]{
			width: 270px;
		}
		&[type=submit]{
			background-image: -ms-linear-gradient(top, #425858 0%, #334242 100%);
			background-image: -moz-linear-gradient(top, #425858 0%, #334242 100%);
			background-image: -o-linear-gradient(top, #425858 0%, #334242 100%);
			background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #425858), color-stop(1, #334242));
			background-image: -webkit-linear-gradient(top, #425858 0%, #334242 100%);
			background-image: linear-gradient(to bottom, #425858 0%, #334242 100%);
			color: #FFF;
			cursor: pointer;
			padding: 0;
			width: 145px;
			height: 52px;
			margin-top: 22px;
			&:hover{
				background: #BF2267;
			}
		}
		&::-webkit-input-placeholder {color:#324141;}
		&::-moz-placeholder          {color:#324141;}
		&:-moz-placeholder           {color:#324141;}
		&:-ms-input-placeholder      {color:#324141;}
	}
	p{
		margin-top: 2px;
		font-family: pt_sans_bold;
	}
	span{
		display: block;
		width: 140px;
		position: absolute;
		right: 57px;
		bottom: 42px;
	}
}

.overlay{
	z-index: 3;
	position: fixed;
	background-color: #000;
	opacity: 0.7;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
	display: none;
}
.form-call{
	width: 342px;
	position: absolute;
	top:45%;
	left: 35%;
	background-image: -ms-linear-gradient(top, #68B7D4 0%, #55A0BC 100%);
	background-image: -moz-linear-gradient(top, #68B7D4 0%, #55A0BC 100%);
	background-image: -o-linear-gradient(top, #68B7D4 0%, #55A0BC 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #68B7D4), color-stop(1, #55A0BC));
	background-image: -webkit-linear-gradient(top, #68B7D4 0%, #55A0BC 100%);
	background-image: linear-gradient(to bottom, #68B7D4 0%, #55A0BC 100%);
	padding: 21px 28px 25px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	font-family: Arial;
	color: #324141;
	font-size: 14px;
	position: fixed;
	top: 45%;
	left: 50%;
	margin-top: -157px;
	margin-left: -199px;
	z-index: 99;
	display: none;
	opacity: 0;
	.formMixin();
	#input_id0{
		width: 162px;
	}
}//form-call
.distributor-form{
	width: 524px;
	position: absolute;
	top:45%;
	left: 35%;
	background-image: -ms-linear-gradient(top, #FFE508 0%, #FFCB08 100%);
	background-image: -moz-linear-gradient(top, #FFE508 0%, #FFCB08 100%);
	background-image: -o-linear-gradient(top, #FFE508 0%, #FFCB08 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFE508), color-stop(1, #FFCB08));
	background-image: -webkit-linear-gradient(top, #FFE508 0%, #FFCB08 100%);
	background-image: linear-gradient(to bottom, #FFE508 0%, #FFCB08 100%);
	padding: 21px 28px 25px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	font-family: Arial;
	color: #324141;
	font-size: 14px;
	position: fixed;
	top: 45%;
	left: 50%;
	margin-top: -204px;
	margin-left: -290px;
	z-index: 99;
	display: none;
	opacity: 0;
	.formMixin();
	h3{color: #324141;}
	.close{color: #324141; top: 0;}
	input[type=text]{width: 210px;}
	textarea{
		resize: none;
		width: 244px;
		height: 163px;
		position: absolute;
		bottom: 139px;
		right: 28px;
		border: none;
		color: #324141;
		font-size: 16px;
		font-family: Arial;
		padding: 13px 15px;
		overflow: hidden;
	}
	p{color: #324141;}
	span{
		width: 215px;
		right: 0;
		left: 190px;
		bottom: 40px;
	}
}
