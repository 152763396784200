.honors {
    .inside-content {
        .honors_prewiev {
            margin-bottom: 40px;

            h1 {
                color: #55a0bc !important;
                font-size: 32px !important;
                padding-bottom: 28px;
            }

            ul {
                list-style: none;

                li {
                    width: 235px;
                    text-align: center;
                    margin-bottom: 40px;
                    display: none;

                    &:nth-child(-n+4) { display: inline-block; }

                    a {
                        text-decoration: none;

                        img { border: none; }

                        p {
							font-family: pt_sans_bold;
							font-size: 17px;
						}
                    }
                }
            }

            .show_more {
                text-align: center;
                display: block;

                a {
                    border-bottom: 1px dotted #c1c6c6;
                    color: #324141;
                    font-family: pt_sans_bold;
                    font-size: 22px;
                    text-decoration: none;

                    &:hover {
                        color: #BF2267;
                        border-bottom: 1px dotted #BF2267;
                    }
                }
            }
        }
        //honors_prewiev
    }
    //inside-content
}
//honors
